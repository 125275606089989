import { useCallback, useEffect } from 'react';
import FAQ_DATA from '@lib/constants/FAQ_DATA';
import { useMobileScreen } from '@lib/view/hooks/screens';
import AOS from 'aos';
import React from 'react';
import { Element, scroller } from 'react-scroll';
import FeedbackSection from '@home/feedback';
import FrequentlyAskQuestion from '@home/frequently-ask-quesiton';
import GrowthSection from '@home/growth';
import MoreDetailItem from '@home/more-details-item';
import SubcriberSection from '@home/subscriber';
import TrackOrderSection from '@home/track-order';
import PolicyAndReview from '@home/policy-and-review';
import Layout from '@lib/view/components/layout';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import Image from 'next/image';

const HomePage: NextPage = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);
    const { push } = useRouter();
    const onScroll = useCallback(() => {
        // event.preventDefault();
        scroller.scrollTo('input-element', {
            offset: 0,
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart',
        });
    }, []);

    const handleClickButton = () => {
        push('/create-order');
    };

    const mobile = useMobileScreen();
    return (
        <Layout footer titleDefault="TubeKick">
            {/* -----  Introduction ----- */}
            <section className="md:pb-0 relative bg-no-repeat bg-cover z-2">
                <div className="hidden lg:flex absolute top-0 right-0 left-0 bottom-0 bg-lavender bg-tiktok-banner-linepals">
                    <div className="flex items-center justify-end w-full container mx-auto ">
                        <Image
                            src="/images/home/tiktok-rp/bg-content-banner2.png"
                            alt=""
                            width={734}
                            height={490}
                            quality={100}
                        />
                    </div>
                </div>
                <div className="flex lg:hidden absolute top-0 right-0 left-0 bottom-0 bg-lavender bg-no-repeat bg-cover" />
                <div className="flex w-full items-center text-white min-h-545px md:min-h-750px md:py-25 pt-25 pb-15">
                    <div className="flex items-center w-full mx-5 md:container md:mx-auto">
                        <div
                            className="text-center md:text-left sm:px-4 w-full max-w-150"
                            data-aos="fade-up"
                        >
                            <h1 className="font-Circular-Std font-bold leading-65px md:leading-90px flex md:justify-start justify-center flex-col">
                                <span className="md:text-70px text-4xl text-blue-medium-slate">
                                    We craft engaging
                                </span>
                                <span className="md:text-96px text-60px text-black">
                                    Content
                                </span>
                            </h1>

                            <Element name="input-element" />
                            <button
                                className=" bg-blue-medium-slate h-15 md:w-251px w-full rounded-lg mt-13 md:mt-10 mb-9 font-Inter font-semibold text-base"
                                onClick={handleClickButton}
                            >
                                Get started today!
                            </button>
                            <PolicyAndReview
                                spacing="md:pt-4 pt-2 mb-10"
                                reviewerSpacing="md:mb-0"
                                fontSizeTitle="text-13px md:text-base whitespace-nowrap md:leading-5 leading-4 font-Inter font-normal md:font-medium text-black"
                                classBoxTitle="flex"
                                spacingChecked="mr-6px"
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* ----- How ViewPals works ----- */}
            <section className="md:container px-5 md:px-35px pt-8 md:pt-110px mx-auto">
                <SubcriberSection
                    onScroll={onScroll}
                    title={mobile ? 'Streamline your' : 'Streamline your'}
                    desc="Simplify and streamline your content production with our innovative order process. Request and receive tailor-made blog posts and content that perfectly match your specifications. Experience the ease of ordering high-quality content with our user-friendly system."
                />
                <GrowthSection
                    desc="Count on our skilled content team to meet your diverse writing needs, from Instagram posts to concise blogs and long-format SEO articles. We deliver exceptional content tailored to your specifications, ensuring your requirements are met with expertise."
                    onScroll={onScroll}
                    title="Long & Short"
                />
            </section>
            {/* ----- Trusted ----- */}
            <section className="h-420px md:h-500px relative mt-16 md:mt-150px  bg-no-repeat bg-cover">
                <FeedbackSection
                    desc="Content Pals revolutionized my copywriting and business growth. Their reliability is unmatched"
                    userName={mobile ? '- Jenny Walts' : '- Wolfreign'}
                />
            </section>

            {/* ------view and subscriber----- */}
            <section className="md:container px-5 mx-auto flex flex-col md:flex-row justify-between space-y-16 space-x-0 md:space-y-0 md:space-x-20 pt-73px md:pt-124px">
                <MoreDetailItem
                    imageName="contentpals-rp-01"
                    title="Blog Posts"
                    desc="Increase conversions and connect with your audience through our expert blog post copywriting services. Our experienced team will craft compelling content that effectively communicates your message, driving results for your business."
                />
                <MoreDetailItem
                    imageName="contentpals-rp-02"
                    title="SEO Blog Posts"
                    desc="Improve search engine rankings and drive traffic to your site with our professional SEO blog post writing services. Our team of experts crafts high-quality, engaging content optimized for search engines, delivering tangible results."
                />
                <MoreDetailItem
                    imageName="contentpals-rp-03"
                    title="Instagram Captions"
                    desc="Stand out from the crowd and boost engagement with our professional caption writing services. Our team crafts captivating, on-brand captions that will transform your Instagram presence. Take your Instagram to the next level starting today!"
                />
            </section>
            {/* -------View and subscriber end------- */}

            {/* ----------- */}

            <TrackOrderSection
                onScroll={onScroll}
                name="-tiktok-rp"
                title="Experienced"
                desc="Place your trust in our team of seasoned professional copywriters. With years of industry experience, we specialize in creating compelling content that resonates with your target audience and drives conversions. Our expertise guarantees exceptional results for your business."
            />

            {/* --------------- */}

            {/* Frequently Ask Quesiton */}
            <section className="bg-white" id="faq">
                <Element name="faq" />
                <div
                    className="md:container px-5 md:px-0 pt-106px md:pt-48 pb-2 mx-auto md:max-w-750px"
                    data-aos="fade-up"
                >
                    <h2 className="text-center font-bold md:font-medium md:text-28px text-22px font-Circular-Std text-violet-blackcurrant">
                        Frequently Asked Questions
                    </h2>

                    <div className="mt-16 md:mt-20">
                        <div className="mb-73px md:mb-32">
                            <FrequentlyAskQuestion data={FAQ_DATA.homepage} />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default HomePage;
