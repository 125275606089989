import React, { ComponentType, ReactElement } from 'react';
import APP_CONFIG from './APP_CONFIG';

const FAQ_DATA = {
    homepage: [
        {
            key: '1',
            title: 'What kind of content can I request from your copywriting services?',
            content: (): ReactElement => (
                <span>
                    Our copywriting services cater to a diverse range of content
                    needs, such as blog articles, social media content, product
                    summaries, website copy, email marketing messages, and much
                    more.
                </span>
            ),
        },
        {
            key: '2',
            title: 'How much time does it take to deliver the finished content?',
            content: (): ReactElement => (
                <span>
                    The delivery time for completed content depends on the
                    requested type and length. Generally, we aim to complete all
                    content within 4-5 business days. For shorter content like
                    brief blog posts or social media updates under 300 words,
                    you can expect a quicker turnaround.
                </span>
            ),
        },
        {
            key: '3',
            title: 'How many revisions do you offer with your service?',
            content: (): ReactElement => (
                <span>
                    We provide up to 2 rounds of revisions to guarantee your
                    satisfaction with the final content.
                </span>
            ),
        },
        {
            key: '4',
            title: 'How can I place an order for your copywriting services?',
            content: (): ReactElement => (
                <span>
                    To place an order, visit our website, choose the required
                    content type, and specify the desired word or character
                    count. After completing the checkout process, you will
                    receive instructions on submitting your content brief.
                </span>
            ),
        },
        {
            key: '5',
            title: 'Is your copywriting team experienced?',
            content: (): ReactElement => (
                <span>
                    Absolutely, our team of skilled copywriters boasts over 8
                    years of experience in various industries, ensuring the
                    delivery of top-notch, customized content to meet your
                    specific requirements.
                </span>
            ),
        },
        {
            key: '6',
            title: 'Are there any discounts available for large orders?',
            content: (): ReactElement => (
                <span>
                    Yes, we offer discounts for larger orders. The more pieces
                    of content you request, the lower the cost per piece.
                </span>
            ),
        },
        {
            key: '7',
            title: 'What is the procedure for providing feedback and asking for revisions?',
            content: (): ReactElement => (
                <span>
                    After receiving your content, you can share your feedback
                    and request revisions by replying to the email containing
                    your content. Our team will collaborate with you to make any
                    necessary adjustments and ensure you are fully satisfied
                    with the results.
                </span>
            ),
        },
    ],
};

export type ItemProps = {
    key: string;
    title: string;
    content: ComponentType<{}>;
};

export default FAQ_DATA;
